<template>
  <div class="box">
    <div class="box__header">
      <h3>Расследование рекламации</h3>

      <div class="buttons">
        <ReclamationExternalPrintActParsing :reclamation="reclamation" />

        <VButton
          v-if="$store.getters['permission/permissionUpdateReclamationInvestigation']"
          text="Редактировать"
          :class="isEdit && 'disabled filter-gray'"
          size="sm"
          icon="edit"
          @click.native="turnOnIsEdit"
        />
      </div>
    </div>

    <ReclamationExternalInvestigationCardDetails
      v-if="!isEdit"
      :reclamation="reclamation"
    />

    <ReclamationExternalInvestigationEditForm
      v-else
      :reclamation="reclamation"
      @update-success="turnOffIsEdit"
      @cancel="turnOffIsEdit"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import { ReclamationExternalInvestigationCardDetails } from '@/entities/reclamation-external'
import { ReclamationExternalInvestigationEditForm } from '@/features/reclamation-external/edit-investigation'
import { ReclamationExternalPrintActParsing } from '@/features/reclamation-external/print-act-parsing'
import { mixinIsEdit } from '@/mixins/mixinIsEdit'
import { mapState } from 'vuex'

export default {
  components: {
    ReclamationExternalInvestigationCardDetails,
    ReclamationExternalInvestigationEditForm,
    ReclamationExternalPrintActParsing,
    VButton
  },
  mixins: [mixinIsEdit],
  computed: {
    ...mapState('reclamationExternal/details', {
      reclamation: 'reclamation'
    })
  }
}
</script>
