<template>
  <div>
    <VForm :value="position">
      <div class="flex gap-m mb-20">
        <InfoTextBlock
          labelText="№ позиции"
          :text="position.sort ? position.sort : '-'"
        />

        <VInput
          label="Кол-во:"
          class="input--row input-text"
          name="value"
          width="initial"
          input-width="65px"
          disabled
        />

        <InfoTextBlock
          labelText="Масса позиции"
          :text="weightPosition"
        />

        <InfoTextBlock
          labelText="Общий вес"
          :text="dirtyWeight"
        />
      </div>

      <VInput
        label="Артикул:"
        class="input--row input-text"
        name="article"
        input-width="450px"
        disabled
      />

      <p
        class="order-position__stock mb-20"
        :style="inStock ? '' : 'color: #898989'"
      >
        В наличии: <span>{{ inStock }}</span>
      </p>

      <VInputFiles
        class="mb-30"
        name="files"
        :is-edit="false"
        with-preview
      />

      <VSelect
        label="Диспетчер производства"
        name="responsible"
        class="mb-30"
        width="350px"
        :options="[]"
        disabled
      />

      <div class="position">
        <VInput
          name="name"
          label="Наименование"
          required
          disabled
        />
      </div>
    </VForm>

    <PositionFormDetails
      :value="position"
      class="mb-s"
      :type-position="position.type"
      has-probes
      is-disabled
    />
  </div>
</template>

<script>
import VForm from '@/components/Form/VForm.vue'
import PositionFormDetails from '@/components/production/position/PositionFormDetails.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import VSelect from '@/components/Form/VSelect.vue'
import VInputFiles from '@/components/Form/VInputFiles/VInputFiles.vue'
import InfoTextBlock from '@/components/ui/AppInfoTextBlock.vue'
import { positionModel } from '@/entities/position'
import { formulaTotalWeight } from '@/utils/production/position/formulas'
import { fixedNumberCopy } from '@/utils/number'
import { mixinInStock } from '@/mixins/mixinInStock'

export default {
  name: 'PositionOnlyRead',
  components: {
    InfoTextBlock,
    VInputFiles,
    VSelect,
    VInput,
    PositionFormDetails,
    VForm
  },
  mixins: [mixinInStock],

  props: {
    position: {
      type: Object,
      required: true
    }
  },

  computed: {
    dirtyWeight () {
      return positionModel.calcConsignmentsDirtyWeight(this.position)
    },

    weightPosition () {
      let weightTotal = this.calcTotalWeight ? this.calcTotalWeight * this.position.value : 0
      if (weightTotal !== 0) {
        weightTotal = fixedNumberCopy(weightTotal, 2)
      }
      return weightTotal
    },

    calcTotalWeight () {
      return formulaTotalWeight(this.position)
    }
  },

  watch: {
    'position.article': {
      handler (article) {
        this.setInStockBy(article)
      },
      immediate: true
    }
  }
}
</script>
