<template>
  <div>
    <VButton
      text="Удалить рекламацию"
      size="sm"
      icon="remove"
      @click.native="openModal"
    />

    <ModalConfirmation
      v-if="isModal"
      title="Удалить рекламацию?"
      @confirm="destroyReclamation"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'
import VButton from '@/components/simple/button/VButton.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { showAlertError } from '@/utils/store/alert'

export default {
  components: { VButton, ModalConfirmation },
  props: {
    reclamationId: {
      type: Number,
      required: true
    }
  },
  mixins: [mixinModal],
  methods: {
    async destroyReclamation () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        await ReclamationApi.destroy(this.reclamationId)

        this.closeModal()
        this.$emit('success-destroy')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    }
  }
}
</script>
