<template>
  <div>
    <VButton
      text="Печать"
      size="sm"
      icon="print"
      @click.native="openModal('print')"
    />

    <ModalPrint
      v-if="isModal === 'print'"
      :init-print-type="options[0].value"
      :print-options="options"
      @request="onSubmit"
      @reject="closeModal"
    />

    <ModalFilesPreview
      v-if="isModal === 'preview'"
      :files="files"
      @close="closeModal"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import ModalPrint from '@/components/ui/Modal/ModalPrint.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { reclamationExternalModel } from '@/entities/reclamation-external'
import { PRINT_OPTIONS } from '../model/const'
import { ReportApi } from '@/api/report/ReportApi'
import { showAlertError } from '@/utils/store/alert'
import ModalFilesPreview from '@/components/files/ModalFilesPreview.vue'

export default {
  components: {
    ModalFilesPreview,
    ModalPrint,
    VButton
  },
  mixins: [mixinModal],
  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  },
  data: () => ({
    files: []
  }),
  computed: {
    options: () => PRINT_OPTIONS
  },
  methods: {
    async onSubmit (formData) {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        const response = await ReportApi.getBy(formData.type, {
          reclamation_id: this.reclamation.id
        })

        this.setFiles({
          url_pdf: response.data.url_pdf,
          url_docx: response.data.url_docx,
          name: response.data.url_pdf.split('/').at(-1)
        })
        this.openModal('preview')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    setFiles (file) {
      this.files.length = 0
      this.files.push(file)
    }
  }
}
</script>
