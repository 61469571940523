<template>
  <ModalForm
    v-model="formData"
    title="Добавить позицию"
    class="modal--title-reset"
    btn-size="xl"
    only-btn-submit
    @request="onSubmit"
    @reject="onReject"
  >
    <VSelect
      label="Добавить позицию:"
      class="mb-m"
      name="position"
      required
      :options="options"
    >
      <Validation for="required" />
    </VSelect>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import Validation from '@/components/Form/Validation'
import VSelect from '@/components/Form/VSelect.vue'
import { reclamationExternalModel } from '@/entities/reclamation-external'
import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { mapMutations } from 'vuex'

export default {
  components: { VSelect, Validation, ModalForm },
  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  },

  data: () => ({
    formData: {
      position: null
    }
  }),

  computed: {
    options () {
      var sortList = function (item1, item2) { return (item1.sort > item2.sort) ? 1 : -1 }
      
      const list = this.reclamation.order.positions.map((position, idx) => ({
        id: position.id,
        idx,
        sort: position.sort,
        name: `№${position.sort} ${position.type.name} (${position.value})`
      }))

      return list.sort(sortList)
    }
  },

  methods: {
    ...mapMutations('reclamationExternal/details', {
      ADD_POSITION: 'ADD_POSITION',
      UPDATE_STATUS_AND_INDICATOR: 'UPDATE_STATUS_AND_INDICATOR'
    }),

    async onSubmit () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        const data = this.getMapped(this.formData)
        await this.updateReclamationPosition(data)

        this.$emit('update-success')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    async updateReclamationPosition (data) {
      const response = await ReclamationApi.update(this.reclamation.id, data)
      data.position = response.data.position
      this.ADD_POSITION(data)
      this.UPDATE_STATUS_AND_INDICATOR(response.data)
      showAlertSuccess('Позиция добавлена')
    },

    getMapped (formData) {
      const { position } = formData

      return {
        // position_number: position.sort,
        position_id: position.id,
        position: this.reclamation.order.positions[position.idx]
      }
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
