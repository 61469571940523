<template>
  <Spinner v-if="isLoading" />

  <div v-else class="column gap-m">
    <div class="between ai-c">
      <VButtonPageBack :callback="onPageBack" />

      <div class="buttons">
        <ReclamationExternalLaunchIntoProduction
          v-if="visibleLaunchIntoProduction"
          :reclamation="reclamation"
        />

        <DestroyReclamation
          v-if="visibleDestroyReclamation"
          :reclamation-id="reclamation.id"
          @success-destroy="goToReclamationList"
        />

        <ItemChangelog
          :subject-id="reclamation.id"
          :subject-type="subjectType"
        />
      </div>
    </div>

    <ReclamationExternalGeneralInformation />

    <ReclamationExternalPositions />

    <ReclamationExternalInvestigation />

    <ReclamationExternalResolution />
  </div>
</template>

<script>
import {
  ReclamationExternalGeneralInformation,
  ReclamationExternalPositions,
  ReclamationExternalInvestigation,
  ReclamationExternalResolution
} from '@/widgets/reclamation-external'
import { ReclamationExternalLaunchIntoProduction } from '@/features/reclamation-external/launch-into-production'
import { reclamationExternalModel } from '@/entities/reclamation-external'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { showAlertError } from '@/utils/store/alert'
import { mapActions, mapMutations, mapState } from 'vuex'
import VButtonPageBack from '@/components/simple/button/VButtonPageBack.vue'
import { DestroyReclamation } from '@/features/reclamation-external/destroy'
import { goToPage, goToPrevPage } from '@/utils/router'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { ItemChangelog } from '@/entities/changelog'

export default {
  name: 'ReclamationDetailsPage',
  components: {
    ItemChangelog,
    DestroyReclamation,
    VButtonPageBack,
    ReclamationExternalGeneralInformation,
    ReclamationExternalPositions,
    ReclamationExternalInvestigation,
    ReclamationExternalResolution,
    ReclamationExternalLaunchIntoProduction
  },
  mixins: [mixinModal, mixinIsLoading],

  computed: {
    ...mapState('reclamationExternal/details', {
      reclamation: 'reclamation',
      reclamationUpdated: 'reclamationUpdated'
    }),
    visibleLaunchIntoProduction () {
      return (
        this.$store.getters['permission/permissionUpdateReclamationResolution'] &&
        (
          this.reclamation.isIndicatorForLaunchIntoProduction() ||
          this.reclamation.isResolutionForLaunchIntoProduction() ||
          this.reclamationUpdated?.indicator === 5
        ) &&
        this.reclamation.hasPosition()
      )
    },

    visibleDestroyReclamation () {
      return (
        this.$store.getters['permission/permissionDestroyReclamation'] &&
        this.reclamation.checkCanDestroy()
      )
    },

    subjectType: () => reclamationExternalModel.CHANGELOG_SUBJECT_TYPE
  },

  watch: {
    '$route.params.id': {
      handler (id) {
        this.loadReclamation(id)
      },
      immediate: true
    }
  },

  destroyed () {
    this.RESET_STATE()
  },

  methods: {
    ...mapMutations('reclamationExternal/details', {
      RESET_STATE: 'RESET_STATE'
    }),
    ...mapActions('reclamationExternal/details', {
      fetchReclamation: 'fetchReclamation'
    }),
    ...mapMutations('scroll', {
      SET_NODE_ID: 'SET_NODE_ID'
    }),
    async loadReclamation (id) {
      try {
        this.startLoading()
        await this.fetchReclamation(id)
      } catch (e) {
        showAlertError(e)
        await goToPage(this.$store.state.pages.reclamationPages.list)
      } finally {
        this.finishLoading()
      }
    },

    goToReclamationList () {
      goToPage(this.$store.state.pages.reclamationPages.list, {
        status: reclamationExternalModel.STATUSES[0].value
      })
    },

    onPageBack () {
      this.SET_NODE_ID(`reclamation-card--${this.reclamation.id}`)
      goToPrevPage()
    }
  }
}
</script>
