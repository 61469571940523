<template>
  <div class="box">
    <div class="box__header">
      <h3>Решение</h3>

      <div class="buttons">
        <VTooltip
          popupText="Сохраните изменения в форме «Общая информация»"
          :showTip="hasTooltip"
          tooltipSide="top"
          style="width: 100%"
        >
          <VButton
            v-if="$store.getters['permission/permissionUpdateReclamationResolution']"
            text="Редактировать"
            :class="(isEdit || isDisableEdit) && 'disabled filter-gray'"
            size="sm"
            icon="edit"
            @click.native="openEditForm"
          />
        </VTooltip>
      </div>
    </div>

    <ReclamationExternalResolutionCardDetails
      v-if="!isEdit"
      :reclamation="reclamation"
    />

    <ReclamationExternalResolutionEditForm
      v-else
      :reclamation="reclamation"
      @update-success="onUpdateSuccess"
      @cancel="closeEditForm"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import { ReclamationExternalResolutionCardDetails } from '@/entities/reclamation-external'
import { ReclamationExternalResolutionEditForm } from '@/features/reclamation-external/edit-resolution'
import { mixinIsEdit } from '@/mixins/mixinIsEdit'
import { mapMutations, mapState } from 'vuex'
import VTooltip from '@/components/ui/VTooltip.vue'

export default {
  components: {
    VTooltip,
    ReclamationExternalResolutionCardDetails,
    ReclamationExternalResolutionEditForm,
    VButton
  },
  mixins: [mixinIsEdit],

  computed: {
    ...mapState('reclamationExternal/details', {
      reclamation: 'reclamation',
      isDisableEdit: 'isDisableEdit'
    }),

    hasTooltip () {
      return this.isDisableEdit && !this.isEdit
    }
  },

  methods: {
    ...mapMutations('reclamationExternal/launchIntoProduction', {
      openModalLaunchIntoProduction: 'OPEN_MODAL'
    }),
    ...mapMutations('reclamationExternal/details', {
      SET_DISABLE_EDIT: 'SET_DISABLE_EDIT'
    }),

    onUpdateSuccess () {
      this.turnOffIsEdit()
      this.SET_DISABLE_EDIT(false)

      if (!this.reclamation.isIndicatorForLaunchIntoProduction()) return

      if (!this.reclamation.hasReclamationOrder()) {
        return this.openModalLaunchIntoProduction('form')
      }

      if (this.reclamation.isStatusLaunchIntoProduction()) {
        this.openModalLaunchIntoProduction('go-to-order')
      }
    },

    openEditForm () {
      this.turnOnIsEdit()
      this.SET_DISABLE_EDIT(true)
    },

    closeEditForm () {
      this.turnOffIsEdit()
      this.SET_DISABLE_EDIT(false)
    }
  }
}
</script>
