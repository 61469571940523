<template>
  <div>
    <VButton
      text="Получить пружины"
      size="sm"
      icon="add"
      @click.native="openModal"
    />

    <ModalForm
      v-if="isModal"
      :reclamation="reclamation"
      @update-success="closeModal"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import ModalForm from './ModalForm.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { reclamationExternalModel } from '@/entities/reclamation-external'

export default {
  components: {
    ModalForm,
    VButton
  },
  mixins: [mixinModal],
  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  }
}
</script>
