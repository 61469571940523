<template>
  <div>
    <VButton
      text="Удалить позицию"
      size="sm"
      icon="remove"
      @click.native="openModal"
    />

    <ModalConfirmation
      v-if="isModal"
      title="Удалить позицию?"
      @confirm="destroyPosition"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'
import VButton from '@/components/simple/button/VButton.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { reclamationExternalModel } from '@/entities/reclamation-external'
import { mapMutations } from 'vuex'

export default {
  components: { VButton, ModalConfirmation },
  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  },
  mixins: [mixinModal],
  methods: {
    ...mapMutations('reclamationExternal/details', {
      DESTROY_POSITION: 'DESTROY_POSITION',
      UPDATE_STATUS_AND_INDICATOR: 'UPDATE_STATUS_AND_INDICATOR'
    }),

    async destroyPosition () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        const data = {
          // position_number: null,
          position_id: null,
          position: null
        }

        const response = await ReclamationApi.update(this.reclamation.id, data)
        this.DESTROY_POSITION(data)
        this.UPDATE_STATUS_AND_INDICATOR(response.data)

        this.closeModal()
        showAlertSuccess('Позиция удалена')
        this.$emit('success-destroy')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    }
  }
}
</script>
