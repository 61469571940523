<template>
  <div>
    <AppAccordion
      class="box"
      init-is-open
      :visible-btn="!!currentPosition"
    >
      <div slot="header">
        <header class="box__header">
          <h3>Позиции</h3>

          <div
            v-if="$store.getters['permission/permissionUpdateReclamationPosition']"
            class="buttons buttons--card-full"
          >
            <ReclamationExternalDestroyPosition
              v-if="visibleDestroyPosition"
              :reclamation="reclamation"
            />

            <ReclamationExternalAddPosition
              v-if="!visibleDestroyPosition && !hasPositions"
              :reclamation="reclamation"
            />
          </div>
        </header>

        <NoContentBox v-if="!currentPosition" text="Позиция не добавлена" />

        <TabsSwiper
          v-if="hasPositions"
          class="tabs-swiper--border mb-30"
          :space-between="30"
        >
          <TabItem
            class="swiper-slide"
            v-for="(item, idx) of tabs"
            :key="item.id"
            :navItem="item"
            :is-active="currentPositionIdx === idx"
            @click.native="setCurrentPositionIdx(idx)"
          />
        </TabsSwiper>
      </div>

      <PositionOnlyRead
        v-if="hasPositions"
        :position="currentPosition"
      />
    </AppAccordion>
  </div>
</template>

<script>
import AppAccordion from '@/components/ui/AppAccordion.vue'
import NoContentBox from '@/components/production/NoContentBox.vue'
import TabItem from '@/components/ui/TabsSwiper/TabItem.vue'
import TabsSwiper from '@/components/ui/TabsSwiper/TabsSwiper.vue'
import PositionOnlyRead from '@/components/production/position/PositionOnlyRead.vue'
import { mapUser } from '@/utils/model/User'
import { ReclamationExternalAddPosition } from '@/features/reclamation-external/add-position'
import { ReclamationExternalDestroyPosition } from '@/features/reclamation-external/destroy-position'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { mapState } from 'vuex'

export default {
  components: {
    PositionOnlyRead,
    TabsSwiper,
    TabItem,
    NoContentBox,
    AppAccordion,
    ReclamationExternalDestroyPosition,
    ReclamationExternalAddPosition
  },
  mixins: [mixinModal],

  data: () => ({
    currentPositionIdx: 0
  }),

  computed: {
    ...mapState('reclamationExternal/details', {
      reclamation: 'reclamation'
    }),

    hasPositions () {
      return Boolean(this.positions.length)
    },

    currentPosition () {
      return this.positions[this.currentPositionIdx] || null
    },

    tabs () {
      const tabs = []

      if (this.reclamation.position_id) {
        tabs.push({
          id: this.reclamation.position_id,
          title: `№${this.reclamation.position.sort} ${this.reclamation.position.type.name}`,
          count: this.reclamation.position.value
        })
      }

      if (this.hasOrderAndPositions) {
        this.reclamation.reclamation_order.positions.forEach(item => {
          tabs.push({
            id: item.id,
            title: `№${this.reclamation.getReclamationOrderPositionNumber(item)} ${item.type.name}`,
            count: item.value,
            sort: item.sort
          })
        })
      }

      tabs.sort(function (a, b) {
        if (a.sort > b.sort) return 1
        if (a.sort < b.sort) return -1
      })

      return tabs
    },

    positions () {
      const positions = []

      if (this.reclamation.position_id) {
        positions.push(this.reclamation.position)
      }

      if (this.hasOrderAndPositions) {
        positions.push(...this.reclamation.reclamation_order.positions.map(item => ({
          ...item,
          sort: this.reclamation.getReclamationOrderPositionNumber(item)
        })))
      }

      positions.map(item => {
        if (item.responsible) item.responsible = mapUser(item.responsible)
      })

      positions.sort(function (a, b) {
        if (a.sort > b.sort) return 1
        if (a.sort < b.sort) return -1
      })

      return positions
    },

    hasOrderAndPositions () {
      return this.reclamation.reclamation_order_id && this.reclamation.reclamation_order?.positions?.length
    },

    visibleDestroyPosition () {
      return this.reclamation.hasPosition() && !this.reclamation.hasReclamationOrder()
    }
  },

  methods: {
    setCurrentPositionIdx (idx) {
      this.currentPositionIdx = idx
    }
  }
}
</script>
