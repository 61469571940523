<template>
  <ModalForm
    v-model="formData"
    title="Пружины получены"
    class="modal--title-reset"
    text-btn-confirm="Сохранить"
    btn-size="xl"
    only-btn-submit
    @request="onSubmit"
    @reject="onReject"
  >
    <VInput
      label="Количество изделий:"
      name="number_received"
      class="mb-30"
      required
      :pattern="PATTERN_LIST.num"
    >
      <Validation for="required"/>
      <Validation for="pattern" key-text="num"/>
    </VInput>

    <VInput
      :label="EInvestigationLabels.weight + ':'"
      class="mb-30"
      name="weight"
      :pattern="PATTERN_LIST.num1000"
      required
    >
      <Validation for="required"/>
      <Validation for="pattern" key-text="num1000" />
    </VInput>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import Validation from '@/components/Form/Validation'
import VInput from '@/components/Form/Vinput/VInput'
import { PATTERN_LIST } from '@/const/validation'
import { reclamationExternalModel } from '@/entities/reclamation-external'
import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { showAlertError } from '@/utils/store/alert'
import { transformToNumbers } from '@/shared/lib/utils/form'
import { mapMutations } from 'vuex'

export default {
  components: { Validation, VInput, ModalForm },
  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  },

  data: () => ({
    formData: {
      number_received: null,
      weight: null
    }
  }),

  computed: {
    PATTERN_LIST: () => PATTERN_LIST,
    EInvestigationLabels: () => reclamationExternalModel.EInvestigationLabels
  },

  methods: {
    ...mapMutations('reclamationExternal/details', {
      UPDATE_SPRING_RECEIVED: 'UPDATE_SPRING_RECEIVED',
      UPDATE_STATUS_AND_INDICATOR: 'UPDATE_STATUS_AND_INDICATOR'
    }),

    async onSubmit () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')

        const data = this.getMapped(this.formData)
        await this.updateReclamationSpringReceived(data)

        this.$emit('update-success')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    async updateReclamationSpringReceived (data) {
      const response = await ReclamationApi.update(this.reclamation.id, data)
      this.UPDATE_SPRING_RECEIVED(data)
      this.UPDATE_STATUS_AND_INDICATOR(response.data)
    },

    getMapped (formData) {
      const copy = {
        ...formData,
        indicator: reclamationExternalModel.INDICATORS[3].id
      }

      transformToNumbers(copy, [
        'number_received',
        'weight'
      ])

      return copy
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
