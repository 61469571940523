<template>
  <VForm
    v-model="formData"
    class="column gap-s"
    @request="submitForm"
  >
    <div class="form-grid--col-2">
      <div class="column gap-xs">
        <VInput
          :label="EInvestigationLabels.investigationReclamation + ':'"
          name="investigation"
          type="textarea"
        />

        <VSelect
          :label="EInvestigationLabels.primeCause + ':'"
          name="reason_reclamation_outer"
          :options="reclamationOuterReasonList"
          multiple
        />

        <VInput
          :label="EInvestigationLabels.primeCauseComment + ':'"
          name="reason_comment"
          type="textarea"
        />
      </div>

      <div class="column gap-xs">
        <VInput
          v-if="reclamation.isSpringReceived()"
          :label="EInvestigationLabels.countSprings + ':'"
          name="number_received"
          :pattern="PATTERN_LIST.num"
        >
          <Validation for="pattern" key-text="num" />
        </VInput>

        <VInput
          v-if="reclamation.isSpringReceived()"
          :label="EInvestigationLabels.weight + ':'"
          name="weight"
          :pattern="PATTERN_LIST.num1000"
        >
          <Validation for="pattern" key-text="num1000" />
        </VInput>

        <p>{{ EInvestigationLabels.responsibleForNonCompliance }}:</p>

        <VSelect
          :label="EInvestigationLabels.leader + ':'"
          class="pl-m"
          name="manager"
          :options="$store.state.users.all"
        />

        <VSelect
          :label="EInvestigationLabels.executor + ':'"
          class="pl-m"
          name="executor"
          :options="$store.state.users.all"
        />
      </div>
    </div>

    <ButtonGroup
      textBtnConfirm="Сохранить"
      textBtnReject="Отмена"
      btn-size="l"
      :loading="$store.state.isSendingRequestPage"
      @reject="$emit('cancel')"
    />
  </VForm>
</template>

<script>
import VForm from '@/components/Form/VForm.vue'
import VSelect from '@/components/Form/VSelect.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup.vue'
import Validation from '@/components/Form/Validation.vue'
import { reclamationExternalModel } from '@/entities/reclamation-external'
import { mapActions, mapMutations, mapState } from 'vuex'
import { PATTERN_LIST } from '@/const/validation'
import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { addIdsTo, addIdTo, transformToNumbers } from '@/shared/lib/utils/form'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'

export default {
  components: {
    Validation,
    ButtonGroup,
    VInput,
    VSelect,
    VForm
  },
  mixins: [mixinIsLoading],

  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  },

  data: () => ({
    formData: {
      investigation: null,
      reason_reclamation_outer: null,
      reason_comment: null,
      number_received: null,
      weight: null,
      manager: null,
      executor: null
    }
  }),

  computed: {
    ...mapState('handbook/reclamationOuterReason', {
      reclamationOuterReasonList: 'list'
    }),
    PATTERN_LIST: () => PATTERN_LIST,
    EInvestigationLabels: () => reclamationExternalModel.EInvestigationLabels
  },

  created () {
    if (!this.reclamationOuterReasonList.length) {
      this.fetchReclamationOuterReasonList()
    }

    this.syncFormData()
  },

  methods: {
    ...mapActions('handbook/reclamationOuterReason', {
      loadList: 'loadList'
    }),

    ...mapMutations('reclamationExternal/details', {
      UPDATE_INVESTIGATION: 'UPDATE_INVESTIGATION',
      UPDATE_STATUS_AND_INDICATOR: 'UPDATE_STATUS_AND_INDICATOR'
    }),

    fetchReclamationOuterReasonList () {
      this.loadList()
    },

    async submitForm () {
      try {
        this.startLoading()

        const data = this.getMapped(this.formData)

        await this.updateReclamationInvestigation(data)

        this.$emit('update-success')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    async updateReclamationInvestigation (data) {
      const response = await ReclamationApi.update(this.reclamation.id, data)
      this.UPDATE_INVESTIGATION(data)
      this.UPDATE_STATUS_AND_INDICATOR(response.data)
      showAlertSuccess('Расследование обновлено')
    },

    getMapped (formData) {
      const copy = {
        ...formData
      }

      addIdTo(copy, [
        'manager',
        'executor'
      ])

      addIdsTo(copy, [
        'reason_reclamation_outer'
      ])

      transformToNumbers(copy, [
        'number_received',
        'weight'
      ])

      return copy
    },

    syncFormData () {
      for (const key in this.formData) {
        if (!this.reclamation[key]) continue

        this.formData[key] = this.reclamation[key]
      }
    }
  }
}
</script>
