<template>
  <div>
    <VForm
      v-model="formData"
      class="column gap-s"
      @request="handleSubmit"
    >
      <div class="form-grid--col-2">
        <div class="column gap-xs">
          <ReclamationExternalSelectResolution
            name="decision"
            :is-disabled="isLoading || !reclamation.hasPosition()"
            :is-sliced="reclamation.hasReclamationOrder() && (reclamation.isStatusLaunchIntoProduction() || reclamation.isStatusComplete())"
          />

          <VInput
            :label="EResolutionLabels.comment + ':'"
            name="decision_comment"
            type="textarea"
            :disabled="isLoading"
          />

          <VSelect
            :label="EResolutionLabels.responsibleInvestigation + ':'"
            name="decision_manager"
            :options="$store.state.users.all"
            searchable
            :disabled="isLoading"
          />
        </div>

        <div class="column gap-xs">
          <VInput
            v-show="visibleSpringDefected"
            :label="EResolutionLabels.countOfSpringDefective + ':'"
            name="number_defected"
            :disabled="isLoading"
            :required="visibleSpringDefected"
            :pattern="PATTERN_LIST.num"
          >
            <Validation for="required" />
            <Validation for="pattern" key-text="num" />
          </VInput>

          <VInput
            v-show="visibleSpringDefected"
            :label="EResolutionLabels.typeLoss + ':'"
            name="metal_loss"
            disabled
            :pattern="PATTERN_LIST.num1000"
          >
            <Validation for="pattern" key-text="num1000" />
          </VInput>
        </div>
      </div>

      <ButtonGroup
        textBtnConfirm="Сохранить"
        textBtnReject="Отмена"
        btn-size="l"
        :loading="isLoading"
        @reject="$emit('cancel')"
      />
    </VForm>

    <ModalConfirmation
      v-if="isModal"
      class="modal--md"
      title=""
      @confirm="handleConfirm"
      @reject="closeAndResetModal"
    >
      <h3 v-if="isModalFinish" class="modal__title">
        Действительно желаете принять решение «Доработать»?
      </h3>

      <h3 v-if="isModalRemake" class="modal__title">
        Действительно желаете принять решение «Переделать»?
      </h3>

      <h3 v-if="(isModalRefund || isModalReject) && reclamation.hasReclamationOrder()" class="modal__title">
        Заказ в производстве. Закрыть его без отгрузки?
      </h3>

      <h3 v-if="isModalRefund" class="modal__title">
        Возврат средств согласован с руководством?
      </h3>

      <h3 v-if="isModalRefund || isModalReject" class="modal__title">
        Рекламация будет переведена в статус «Расследование завершено».
        Продолжить?
      </h3>
    </ModalConfirmation>
  </div>
</template>

<script>
import VForm from '@/components/Form/VForm.vue'
import VSelect from '@/components/Form/VSelect.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup.vue'
import Validation from '@/components/Form/Validation.vue'
import {
  reclamationExternalModel,
  ReclamationExternalSelectResolution
} from '@/entities/reclamation-external'
import { PATTERN_LIST } from '@/const/validation'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'
import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { mapMutations } from 'vuex'
import { addIdTo, transformToNumbers } from '@/shared/lib/utils/form'

export default {
  components: {
    ModalConfirmation,
    Validation,
    ButtonGroup,
    VInput,
    VSelect,
    VForm,
    ReclamationExternalSelectResolution
  },
  mixins: [mixinModal, mixinIsLoading],

  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  },

  data: () => ({
    formData: {
      decision: null,
      decision_comment: null,
      decision_manager: null,
      number_defected: null,
      metal_loss: null
    },
    modal: {
      isModal: false,
      resolutionId: null
    }
  }),

  computed: {
    PATTERN_LIST: () => PATTERN_LIST,
    EResolutionLabels: () => reclamationExternalModel.EResolutionLabels,

    visibleSpringDefected () {
      return this.formData.decision?.id === reclamationExternalModel.RESOLUTIONS[2].id
    },

    isModalRemake () {
      return this.modal.resolutionId === reclamationExternalModel.RESOLUTIONS[2].id
    },
    isModalFinish () {
      return this.modal.resolutionId === reclamationExternalModel.RESOLUTIONS[1].id
    },
    isModalReject () {
      return this.modal.resolutionId === reclamationExternalModel.RESOLUTIONS[3].id
    },
    isModalRefund () {
      return this.modal.resolutionId === reclamationExternalModel.RESOLUTIONS[4].id
    }
  },

  watch: {
    'formData.number_defected' (val) {
      const count = +val

      if (Number.isNaN(count) || !Number.isInteger(count)) {
        this.formData.metal_loss = null
        return
      }

      this.formData.metal_loss = this.reclamation.calcMetalLoss(count)
    }
  },

  created () {
    this.syncFormData()
  },

  methods: {
    ...mapMutations('reclamationExternal/details', {
      UPDATE_RESOLUTION: 'UPDATE_RESOLUTION',
      UPDATE_STATUS_AND_INDICATOR: 'UPDATE_STATUS_AND_INDICATOR'
    }),

    handleSubmit () {
      const resolutionId = this.formData.decision?.id
      if (resolutionId !== reclamationExternalModel.RESOLUTIONS[0].id) {
        this.setModalResolutionId(resolutionId)

        return this.openModal()
      }

      this.handleUpdate()
    },

    handleConfirm () {
      this.closeAndResetModal()
      this.handleUpdate()
    },

    async handleUpdate () {
      try {
        this.startLoading()
        const data = this.getMapped(this.formData)
        await this.updateReclamationResolution(data)
        this.showSuccess()
        this.$emit('update-success')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    async updateReclamationResolution (data) {
      const response = await ReclamationApi.update(this.reclamation.id, data)
      this.UPDATE_RESOLUTION(data)
      this.UPDATE_STATUS_AND_INDICATOR(response.data)
    },

    showSuccess () {
      if (this.reclamation.hasReclamationOrder() && this.reclamation.isStatusLaunchIntoProduction()) {
        if (this.reclamation.isResolutionReject()) {
          showAlertSuccess('Заказ в производстве закрыт. Примите решение по изделиям, которые были испытаны')
        } else if (this.reclamation.isResolutionRefund()) {
          showAlertSuccess('Заказ в производстве закрыт')
        }
      } else {
        showAlertSuccess('Решение обновлено')
      }
    },

    getMapped (formData) {
      let data = {
        decision: formData.decision?.id || null,
        decision_comment: formData.decision_comment,
        decision_manager: formData.decision_manager
      }

      addIdTo(data, ['decision_manager'])

      if (data.decision === reclamationExternalModel.RESOLUTIONS[2].id) {
        data = {
          ...data,
          number_defected: formData.number_defected,
          metal_loss: formData.metal_loss
        }
        transformToNumbers(data, ['number_defected'])
      }

      return data
    },

    syncFormData () {
      for (const key in this.formData) {
        if (!this.reclamation[key]) continue

        if (key === 'decision') {
          this.formData.decision = reclamationExternalModel.RESOLUTIONS[this.reclamation.decision - 1]
          continue
        }

        this.formData[key] = this.reclamation[key]
      }
    },

    closeAndResetModal () {
      this.closeModal()
      this.resetModalResolutionId()
    },

    setModalResolutionId (id) {
      this.modal.resolutionId = id
    },

    resetModalResolutionId () {
      this.modal.resolutionId = null
    }
  }
}
</script>
